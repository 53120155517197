<template>
  <div v-if="isLoading == false">
    <v-dialog v-model="verifModal.value" width="99%" max-width="200px">
      <v-card>
        <v-col style="font-weight: bold">
          {{ verifModal.label }}
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                @click="verifModal.value = false"
                style="background-color: #e53935; color: white"
              >
                Non
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                @click="verifModal.callback"
                style="background-color: #388e3c; color: white"
              >
                Oui
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isClientEditionOpen" persistent width="97%" max-width="900px">
      <v-card>
        <v-col
          style="
            font-weight: bold;
            color: white;
            background-color: #7986cb;
            position: relative;
          "
          align="center"
        >
          Modification informations client
          <v-btn
            density="compact"
            icon
            style="
              position: absolute;
              right: 10px;
              top: 10px;
              color: white;
              background-color: #e53935;
            "
            @click="closeEditionClient"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-text-field
            v-model="tmpClient.SOCIETE"
            label="Client"
            disabled
          ></v-text-field>
          <v-text-field v-model="tmpClient.ADRESSE" label="Adresse"></v-text-field>
          <v-text-field v-model="tmpClient.VILLE" label="Ville"></v-text-field>
          <v-text-field
            v-model="tmpClient.CODE_POSTAL"
            label="Code postal"
          ></v-text-field>
          <v-text-field v-model="tmpClient.TELEPHONE" label="Téléphone"></v-text-field>
          <v-text-field
            v-model="tmpClient['CONTACT_MAIL(1)']"
            label="Mail"
          ></v-text-field>
        </v-col>
        <v-col align="center">
          <v-btn color="rgb(67, 160, 71)" style="color: white" @click="clientEdition">
            Valider mes modifications
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="clientInformation.modal" width="99%" max-width="600">
      <v-card>
        <v-col
          style="font-weight: bold; color: white; background-color: #7986cb"
          align="center"
        >
          Création de client
        </v-col>
        <v-divider></v-divider>
        <v-col>
          <v-row>
            <v-col cols="6" v-for="elem in clientInformation.data" :key="elem.value">
              <v-autocomplete
                v-if="elem.value == 'city'"
                hide-details
                v-model="elem.content"
                variant="outlined"
                :label="elem.label"
                :items="cityListe"
                no-data-text="Aucune sélection disponible"
              ></v-autocomplete>
              <v-autocomplete
                v-else-if="elem.value == 'civility'"
                hide-details
                v-model="elem.content"
                variant="outlined"
                :label="elem.label"
                :items="['Monsieur', 'Madame', 'Autre']"
                no-data-text="Aucune sélection disponible"
              ></v-autocomplete>
              <v-text-field
                v-else
                variant="outlined"
                hide-details
                :type="elem.type"
                v-model="elem.content"
                :label="elem.label"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="clientInformation.error && clientInformation.error.length > 0"
          style="color: red; font-weight: bold"
          align="center"
        >
          {{ clientInformation.error }}
        </v-col>
        <v-col align="center">
          <v-btn color="rgb(67, 160, 71)" style="color: white" @click="clientCreation">
            Créer un client
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="creationContratValidation" width="auto">
      <v-card>
        <v-col class="pb-0">
          <div class="pa-2" style="font-weight: bold">
            Valider la création de mon contrat ?
          </div>
        </v-col>
        <v-col class="pt-0">
          <v-row justify="center" align="center">
            <v-col cols="auto">
              <v-btn color="green" @click="finalize"> Oui </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="red" @click="creationContratValidation = false"> Non </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <div style="position: absolute; right: 10px">
      <v-btn
        icon="mdi-close-circle"
        density="small"
        variant="text"
        color="#E57373"
        style="font-size: 20px"
        @click="close"
      >
      </v-btn>
    </div>
    <v-col>
      <v-col class="mb-0 pb-0" align="center">
        <h1
          class="mb-0 pb-0"
          style="
            background-color: #7986cb;
            color: white;
            border-radius: 10px;
            position: relative;
          "
        >
          Création de contrat
          <v-btn
            class="mr-3 pt-1"
            icon="mdi-calendar"
            variant="flat"
            color="rgb(56, 142, 60)"
            style="
              color: white;
              font-size: 11px;
              position: absolute;
              right: 0px;
              top: 10px;
            "
            @click="setShowPlanningDispo"
            density="compact"
          >
          </v-btn>
        </h1>
      </v-col>
      <Steppy
        v-model:step="step"
        doneText="Créer mon contrat"
        nextText="Suivant"
        backText="Retour"
        :tabs="tabs"
        :finalize="
          function () {
            creationContratValidation = true;
          }
        "
        :primaryColor1="'#7986CB'"
        :primaryColor2="'#ECEFF1'"
      >
        <template #1>
          <v-col cols="12">
            <h3>
              Informations client
              <v-btn
                class="ml-3"
                density="compact"
                color="#7986CB"
                style="color: white"
                variant="flat"
                @click="creationCLientForm"
                icon="mdi-plus"
              ></v-btn>
            </h3>
          </v-col>
          <v-col>
            <v-col cols="12" class="px-0">
              <v-card variant="outlined">
                <v-autocomplete
                  v-model="form['client']"
                  class=""
                  :items="clientListe"
                  item-title="SOCIETE"
                  return-object
                  label="Client sélectionné"
                  hide-details
                  no-data-text="Aucun client de disponible"
                >
                </v-autocomplete>
              </v-card>
            </v-col>
            <v-col style="position: relative" class="pa-0">
              <v-btn
                v-if="form['client']"
                style="
                  z-index: 2;
                  position: absolute;
                  right: 1px;
                  border: solid 1px lightgray;
                "
                @click="setEditionClienOpen(form['client'])"
                icon
                density="compact"
              >
                <v-icon size="15">mdi-pen</v-icon></v-btn
              >
              <table
                v-if="form['client'] != undefined"
                style="background-color: #9e9e9e; width: 100%; position: relative"
              >
                <tr
                  v-for="(elem, idx) in clientKeyToShow"
                  :key="idx"
                  :style="{ 'background-color': idx % 2 == 0 ? '#FAFAFA' : '#ECEFF1' }"
                >
                  <td align="left" style="font-weight: bold">{{ elem.label }}</td>
                  <td align="left">
                    <div v-if="elem.key == 'ADRESSE'">
                      {{ form["client"][elem.key] }} {{ form["client"]["VILLE"] }}
                      {{ form["client"]["CODE_POSTAL"] }}
                    </div>
                    <div v-else>
                      {{ form["client"][elem.key] }}
                    </div>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-col>
        </template>
        <template #2>
          <v-col cols="6">
            <h3>
              Informations Materiel
              <v-btn
                class="ml-3"
                density="compact"
                color="#7986CB"
                style="color: white"
                variant="flat"
                @click="addMateriel"
                icon="mdi-plus"
              ></v-btn>
            </h3>
          </v-col>
          <div style="max-height: 50vh; overflow: auto">
            <v-card
              v-for="(materiel, idx) in form.materiel"
              :key="idx"
              color="#7986CB"
              class="mb-3 pa-2"
              :id="'matElem-' + idx"
            >
              <v-btn
                class="ml-3"
                density="compact"
                color="red"
                style="position: absolute; right: 5px; top: 5px"
                @click="form.materiel = form.materiel.splice(index, 1)"
                icon="mdi-delete"
              ></v-btn>
              <v-col style="color: white; font-weight: bold">
                Materiel N° {{ idx + 1 }}
              </v-col>
              <v-col cols="12" class="pa-1">
                <v-combobox
                  variant="solo"
                  class="edition"
                  v-model="materiel['family']"
                  return-object
                  item-title="WEB_NOM_FAMILLE"
                  :items="familyListe"
                  hide-details
                  label="Famille"
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" class="pa-1" v-if="materiel['family']">
                <v-combobox
                  variant="solo"
                  class="edition"
                  @update:modelValue="changeSubFamily"
                  v-model="materiel['subFamily']"
                  return-object
                  item-title="fieldData.titre_1"
                  :items="
                    subFamilyListe.filter(
                      (val) =>
                        val.fieldData['Famille Parc'] ==
                        materiel['family']['Nom_Famille_Principale']
                    )
                  "
                  hide-details
                  label="Sous famille"
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" class="pa-1" v-if="materiel['subFamily']">
                <v-combobox
                  variant="solo"
                  class="edition"
                  v-model="materiel['materiel']"
                  return-object
                  item-title="MATRICULE"
                  :items="
                    parcListe.filter(
                      (val) =>
                        val.SOUS_FAMILLE ==
                        materiel['subFamily'].fieldData['Nom_Genre_Machine']
                    )
                  "
                  hide-details
                  label="Matricule"
                  @update:modelValue="matriculeChange(materiel)"
                >
                </v-combobox>
                <v-col
                  v-if="
                    materiel['materiel'] &&
                    matriculIsFree[materiel['materiel'].MATRICULE_UID]
                  "
                  class="pb-0"
                >
                  <div
                    v-if="
                      matriculIsFree[materiel['materiel'].MATRICULE_UID].data
                        .disponible == 'false'
                    "
                  >
                    <v-chip
                      style="background-color: red; color: white; font-weight: bold"
                    >
                      Le {{ materiel["materiel"].MATRICULE }}
                      n'est pas disponible
                    </v-chip>
                  </div>
                  <div
                    v-if="
                      matriculIsFree[materiel['materiel'].MATRICULE_UID].data
                        .disponible == 'true'
                    "
                  >
                    <v-chip
                      style="background-color: green; color: white; font-weight: bold"
                    >
                      Le
                      {{ materiel["materiel"].MATRICULE }}
                      est disponible
                    </v-chip>
                  </div>
                </v-col>
                <v-col
                  v-if="selectedSubFamilyData && materiel['materiel']"
                  class="px-0 pt-3"
                >
                  <v-combobox
                    label="Type de location"
                    v-model="materiel['subscription']"
                    class="edition"
                    hide-details
                    variant="solo"
                    :items="selectedSubFamilyData.portalData.CAT_Tarif_GM_id"
                    return-object
                    item-title="CAT_Tarif_GM_id::Nom_Grille"
                  >
                    <!-- <template v-slot:item="{ props, item }">
                      <v-list-item v-bind="props">
                        <div>
                          {{ item.value["CAT_Tarif_nom_id::T1_Tarif"] }} :
                          {{ item.value["CAT_Tarif_nom_id::libelle_plein"] }}
                        </div>
                        <div>
                          {{ item.value["CAT_Tarif_nom_id::T2_Tarif"] }} :
                          {{ item.value["CAT_Tarif_nom_id::libelle_reduit"] }}
                        </div>
                      </v-list-item>
                    </template> -->
                  </v-combobox>
                </v-col>
                <v-col v-if="materiel['subscription']">
                  <v-card align="left">
                    <v-col>
                      <div>
                        {{ materiel["subscription"]["CAT_Tarif_GM_id::T1_Tarif"] }}€ :
                        {{ materiel["subscription"]["CAT_Tarif_nom_id::libelle_plein"] }}
                      </div>
                      <br />
                      <div>
                        {{ materiel["subscription"]["CAT_Tarif_GM_id::T2_Tarif"] }}€ :
                        {{ materiel["subscription"]["CAT_Tarif_nom_id::libelle_reduit"] }}
                      </div>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col v-if="materiel['subscription']">
                  <v-card>
                    <v-col align="left" style="font-weight: bold"> Accessoire(s)</v-col>
                    <!-- {{ selectedSubFamilyData.portalData }} -->
                    <v-col>
                      <v-row class="pb-2">
                        <v-col
                          v-for="(elem, i) in selectedSubFamilyData.portalData
                            .ComplementLocation"
                          :key="i"
                          cols="12"
                          class="py-1"
                        >
                          <v-card variant="flat" style="border: solid 1px lightgrey">
                            <v-col>
                              <v-row justify="center" align="center">
                                <v-col align="left" class="py-0">
                                  {{ elem["ComplementLocation::Désignation"] }}
                                  <div style="font-size: 11px; color: grey">
                                    {{ elem["ComplementLocation::titre3"] }}
                                  </div>
                                </v-col>
                                <v-col cols="auto" class="">
                                  <!-- v-model="materiel['acc']['ComplementLocation::ID_GM_ACC']" -->
                                  <v-card>
                                  {{materiel }}
                                    <v-select
                                    v-model="materiel['acc'][elem['ComplementLocation::Désignation']]"
                                      density="compact"
                                      hide-details
                                      :items="[0, 1, 2, 3, 4, 5]"
                                    ></v-select>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-col>
            </v-card>
          </div>
        </template>
        <template #3>
          <v-col cols="12">
            <h3>Agence</h3>
          </v-col>
          <v-col>
            <v-select
              :items="agencyListe"
              hide-details
              item-title="ag_nom"
              return-object
              v-model="form['agence']"
            ></v-select>
          </v-col>
        </template>
        <template #4>
          <v-col cols="12">
            <h3>Lieu de retrait</h3>
          </v-col>
          <v-col>
            <v-select
              :items="retraitListe"
              hide-details
              item-title="PR_NOM_AG"
              return-object
              v-model="form['rdv']"
            ></v-select>
          </v-col>
        </template>
        <template #5>
          <v-col cols="12">
            <h3>Date de début de location</h3>
          </v-col>
          <v-col>
            <input
              class="pa-2"
              style="border: solid 1px lightgrey; border-radius: 5px"
              v-model="form['date']"
              type="date"
            />
          </v-col>
        </template>
      </Steppy>
    </v-col>
  </div>
  <v-col v-else align="center" class="pa-5">
    <v-progress-circular :size="150" :width="5" color="#7986CB" indeterminate
      >Chargement</v-progress-circular
    >
  </v-col>
</template>

<script>
import { Steppy } from "vue3-steppy";
// import 'vue-step-wizard/dist/vue-step-wizard.css'
import { ref } from "@vue/reactivity";
import { watch } from "vue";
import moment from "moment";
import engine from "../core/DataApi";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    agencyListe: {},
    getClientListe: { type: Function },
    setShowPlanningDispo: { type: Function },
    close: { type: Function },
    get_user_information: {},
    clientListe: {},
    parcListe: {},
    familyListe: {},
    subFamilyListe: {},
    retraitListe: {},
    contrat_informations: {},
  },
  data() {
    return {
      materiel: { acc: {} },
      verifModal: {},
      tmpClient: undefined,
    };
  },
  setup() {
    const matriculIsFree = ref({});
    const selectedSubFamilyData = ref({});
    const form = ref({});
    const cityListe = ref(undefined);
    const step = ref(1);
    const clientInformation = ref({});
    const tabs = ref([
      {
        key: "client",
        title: "Client",
        iconSuccess: null,
        isValid: false,
      },
      {
        key: "materiel",
        title: "Materiel",
        iconSuccess: null,
        isValid: false,
      },
      {
        key: "agence",
        title: "Agence",
        iconSuccess: null,
        isValid: false,
      },
      {
        key: "rdv",
        title: "RDV",
        iconSuccess: null,
        isValid: false,
      },
      {
        key: "date",
        title: "Date",
        iconSuccess: null,
        isValid: false,
      },
    ]);
    const clientKeyToShow = ref([
      //    { key: 'VILLE', label: 'Ville' },
      { key: "ADRESSE", label: "Adresse" },
      { key: "TELEPHONE", label: "Téléphone" },
      { key: "CONTACT_MAIL(1)", label: "Mail" },
      //      { key: 'CODE_POSTAL', label: 'Code postal' },
    ]);
    const matriculKeyToShow = ref([
      { key: "FAMILLE", label: "Famille" },
      { key: "SOUS_FAMILLE", label: "Sous famille" },
      { key: "NUMERO_SERIE", label: "Numéro de série" },
      { key: "MARQUE", label: "Marque" },
    ]);
    const isLoading = ref(false);
    const creationContratValidation = ref(false);
    const isClientEditionOpen = ref(false);

    const sameArray = function (arr1, arr2) {
      if (arr1.length != arr2.length) {
        return false;
      }
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] != arr2[i]) {
          return false;
        }
      }
      return true;
    };

    watch(
      () => form,
      (currentValue) => {
        let formKeysTab = Object.keys(currentValue.value);

        for (let i = 0; i < formKeysTab.length; i++) {
          let tmpKey = formKeysTab[i];
          let findedElem = tabs.value.find((val) => val.key == tmpKey);

          if (tmpKey == "materiel") {
            if (findedElem != undefined) {
              findedElem["isValid"] = true;
              if (tmpKey == "materiel") {
                let isFilled = true;
                let keys = ["family", "subFamily", "materiel", "subscription"];

                if (currentValue.value.materiel.length == 0) isFilled = false;

                for (let a = 0; a < currentValue.value.materiel.length; a++) {
                  let localKeys = Object.keys(currentValue.value.materiel[a]);

                  if (!sameArray(keys, localKeys)) isFilled = false;
                }

                findedElem["isValid"] = isFilled;
              }
            }
          } else {
            findedElem["isValid"] = true;
          }
        }
      },
      { deep: true }
    );

    return {
      form,
      step,
      tabs,
      clientKeyToShow,
      matriculKeyToShow,
      creationContratValidation,
      isLoading,
      matriculIsFree,
      clientInformation,
      cityListe,
      isClientEditionOpen,
      selectedSubFamilyData,
    };
  },
  components: {
    Steppy,
  },
  mounted() {
    this.form["date"] = moment().format("YYYY-MM-DD");
    this.form["agence"] = this.agencyListe[0];

    this.getCityListe();
  },
  methods: {
    changeSubFamily(subFamily) {
      let that = this;

      engine.get_sub_family_info(subFamily.fieldData.Nom_Genre_Machine, function (data) {
        that.selectedSubFamilyData = data.data.data[0];
        console.log("selectedSubFamilyData", that.selectedSubFamilyData);
      });
    },
    closeEditionClient() {
      let that = this;
      this.verifModal = {
        value: true,
        label: "Les données modifié ne seront pas enregistrée",
        callback: function () {
          that.isClientEditionOpen = false;
          that.verifModal.value = false;
        },
      };
    },
    clientEdition() {
      let that = this;
      this.isLoading = true;

      engine.edit_client(this.tmpClient, function () {
        that.getClientListe();
        that.isClientEditionOpen = false;
        that.isLoading = false;
      });
    },
    setEditionClienOpen(client) {
      this.tmpClient = JSON.parse(JSON.stringify(client));
      this.isClientEditionOpen = true;
    },
    addMateriel() {
      if (this.form.materiel == undefined) this.form.materiel = [];
      this.form.materiel.push({});
      setTimeout(() => {
        let id = "matElem-" + (this.form.materiel.length - 1);
        let card = document.getElementById(id);
        card.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    clientCreation() {
      let that = this;
      let finalForm = {};

      for (let i = 0; i < this.clientInformation.data.length; i++) {
        let key = this.clientInformation.data[i].value;
        let label = this.clientInformation.data[i].label;
        let value = this.clientInformation.data[i].content;

        if (value == undefined && this.clientInformation.data[i].mandatory == true) {
          setInterval;
          this.clientInformation.error = "Champ nécéssaire : " + label;
          setTimeout(() => {
            that.clientInformation.error = undefined;
          }, 4000);
          return;
        }
        finalForm[key] = value;
      }

      engine.client_create(finalForm, function (data) {
        console.log("client_create", data);
        console.log("clientListe", that.clientListe);
        if (data.code == 0) {
          that.getClientListe();
          // <v-autocomplete v-model="form['client']" class="edition" :items="clientListe" item-title="SOCIETE"

          that.clientInformation = {};
          setTimeout(() => {
            that.form["client"] = that.clientListe.find(
              (val) => val.ID_WEB == data.data.id_web
            );
          }, 2000);
        } else {
          that.clientInformation.error = "ERREUR : Email";
          setTimeout(() => {
            that.clientInformation.error = undefined;
          }, 4000);
        }
      });
    },
    creationCLientForm() {
      this.clientInformation = {
        modal: true,
        data: [
          {
            label: "Civilité",
            value: "civility",
            content: undefined,
            type: "text",
            mandatory: true,
          },
          {
            label: "Prénom",
            value: "firstname",
            content: undefined,
            type: "text",
            mandatory: true,
          },
          {
            label: "Nom",
            value: "lastname",
            content: undefined,
            type: "text",
            mandatory: true,
          },
          {
            label: "Mail",
            value: "email",
            content: undefined,
            type: "mail",
            mandatory: true,
          },
          {
            label: "Adresse",
            value: "address",
            content: undefined,
            type: "text",
            mandatory: true,
          },
          {
            label: "Code postal",
            value: "zipcode",
            content: undefined,
            type: "number",
            mandatory: true,
          },
          {
            label: "Ville",
            value: "city",
            content: undefined,
            type: "text",
            mandatory: true,
          },
          {
            label: "Téléphone",
            value: "mobile",
            content: undefined,
            type: "tel",
            mandatory: true,
          },
          {
            label: "Date de naissance",
            value: "birthdate",
            content: undefined,
            type: "date",
            mandatory: false,
          },
        ],
      };
    },
    getCityListe() {
      let that = this;
      engine.get_city_liste(function (data) {
        if (data.code == 0) {
          that.cityListe = data.data.data.map((val) => val.fieldData.Name);
        }
      });
    },
    matriculeChange(mat) {
      let that = this;
      // engine.is_materiel_free({ UID: this.form['materiel'].MATRICULE_UID }, function (data) {
      //   that.matriculIsFree = data
      // })
      engine.is_materiel_free({ UID: mat.materiel.MATRICULE_UID }, function (data) {
        that.matriculIsFree[mat.materiel.MATRICULE_UID] = data;
      });
    },
    finalize() {
      let that = this;
      this.isLoading = true;
      let mandatoryData = [];
      let idContrat = uuidv4();

      for (let i = 0; i < this.form.materiel.length; i++) {
        let mat = this.form.materiel[i];
        mandatoryData.push({
          CLD_CONTROLE_DEPART: "",
          CLD_CONTROLE_RETOUR: "",

          CLD_MATRICULE: mat.materiel.MATRICULE,
          CLD_DATE_DEPART: moment(this.form["date"]).format("MM/DD/YYYY"),
          CLD_DATE_RETOUR: moment(this.form["date"])
            .add(
              mat.subscription["CAT_Tarif_nom_id::duree"],
              mat.subscription["CAT_Tarif_nom_id::unite"]
            )
            .format("MM/DD/YYYY"),
          CLD_DESCRIPTION: mat.family.WEB_NOM_FAMILLE,

          CLD_ID_SOCIETE: this.get_user_information.user_id_societe,
          // 'CLD_ID_AGENCE': this.get_user_information.user_id_agence,

          CLD_ID_CONTRAT_WEB: idContrat,
          CLD_ID_WEB: uuidv4(),
          CLD_ID_CLIENT: this.form.client.id,
          CLD_ID_LIEU_RETRAIT: this.form.rdv.PR_ID_AG,
          CLD_ID_AGENCE: this.form.agence.ID_Agence,
          CLD_ID_DUREE_LOCATION: mat.subscription["CAT_Tarif_GM_id::ID_Grille"],

          CLD_SOUS_FAMILLE: mat.family.Nom_Famille_Principale,
          CLD_NOM_CLIENT: this.form.client.SOCIETE,
        });
      }

      engine.creat_contrat(mandatoryData, function (data) {
        that.close(data);
        that.isLoading = false;
      });
    },
  },
  computed: {
    getContratSelectedInformations() {
      let elem = this.contrat_informations.find(
        (val) =>
          val.fieldData.Nom_Genre_Machine ==
          this.form.subFamily.fieldData.Nom_Genre_Machine
      );
      if (elem) return elem;
      else return undefined;
    },
    getAccListe() {
      if (this.getSelectedSubFamilyData) {
        return this.getSelectedSubFamilyData.portalData.ComplementLocation;
      } else return undefined;
    },
    getSubscriptionListe() {
      if (this.getSelectedSubFamilyData) {
        return this.getSelectedSubFamilyData.portalData.CAT_Tarif_GM_id;
      } else return undefined;
    },
    getSelectedSubFamilyData() {
      if (this.form["subFamily"]) {
        return this.contrat_informations.find(
          (val) =>
            val.fieldData.Nom_Genre_Machine == this.form["subFamily"].Nom_Genre_Machine
        );
      } else return undefined;
    },
  },
};
</script>

<style></style>
